export const environment = {
  production: true,
  maintenance: false,
  modeso: {
    apiGateway: 'https://api.digitalvoucher-pat.twint.ch/api/'
  },
  defaultLanguage: 'de-ch',
  availableLanguages: ['de-ch', 'fr-ch', 'it-ch', 'en-us'],
  enableDebugging: '',
  dataDomain: 'digitalvoucher-pat.twint.ch/'

};
